import { MutationPayload } from 'vuex';
import Input from '@/components/form/input.vue';
import Textarea from '@/components/form/textarea.vue';
import Radios from '@/components/form/radios.vue';
import Checkboxes from '@/components/form/checkboxes.vue';
import Select from '@/components/form/select.vue';

import { getEventBrandFields, getEventBrandAnswers, updateEventBrandAnswers } from '@/api/brands';
import { ParticipatingFieldsEntity, IParticipatingAnswer } from '@/models/brand';
import {
  IOption, IRadios, IInput,
} from '@/components/form';
import firstLetterUpperCase from '@/utils/firstLetterUppercase';
import store from '@/store';
import isJson from '@/utils/isJson';
import { editExhibitor } from '@/api/exhibitors';
import { uploadFile, getFileById } from '@/api/files';
import Modal from '@/components/modal.vue';
import Cropper from '@/components/form/cropper.vue';
import Description from '@/components/form/description.vue';
import isEmail from '@/utils/isEmail';
import isUrl from '@/utils/isUrl';
import {
  participatingInputs, inputs, basicForm,
} from '.';
import { convertBridgeTitles } from '../../ExhibitorOnboarding';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import router from '@/router';

export default defineComponent({
  components: {
    Input, Textarea, Radios, Checkboxes, Select, Modal, Cropper, Description,
  },

  data() {
    return {
      fields: [] as ParticipatingFieldsEntity[] | null | undefined,
      inputs: inputs(),
      basicForm: basicForm,
      participatingInputs: [...participatingInputs],
      answers: [] as IParticipatingAnswer[],
      loading: false,
      loadingBasic: false,
      cropper: {
        image: '',
        onSelect: (blob: string) => { console.log(blob) },
        aspectRatio: null as any,
      },
      cropFieldId: 0,
      cropping: true,
      croppedFileName: '',
      confirming: false,
      cropSrc: '',
      modal: {
        isActive: false,
        isCard: true,
      },
      event: 0,
      exhibitor: 0,
    }
  },
  setup() {
    const { contextEvent, translations, contextExhibitor } = useContext();
    return {
      contextEvent, translations, contextExhibitor
    }
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.loading = true;

    this.cropper.onSelect = this.onSelectCrop;

    if (this.contextEvent.id) {
      this.getEventBrandForm();
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent') {
          this.getEventBrandForm();
          unsubscribe();
        }
      });
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setLanguage') {
          if (this.contextEvent.id) {
            this.getEventBrandForm();
          }
          this.inputs = inputs();
        }
      });
    }
  },
  methods: {
    syncVisitorName(name: string) {
      if (this.contextExhibitor.id) {
        store.commit('setContextVisitor', { ...this.contextExhibitor, name });
      }
    },
    close() {
      router.push(`/event/${this.event}/manage-exhibitor/${this.exhibitor}/profile`)
    },
    fieldDependency(field: ParticipatingFieldsEntity) {
      if (this.fields && field.participating_field.dependence_field) {
        const fieldDepending = this.fields.find(
          (dField) => field.participating_field.dependence_field === dField.participating_field.id,
        );
        if (fieldDepending && field.participating_field.dependence_operator === '==') {
          if (field.participating_field.dependence_value.includes(',')) {
            const allowedValues = field.participating_field.dependence_value.split(',');
            let dependencyValid = false;
            allowedValues.forEach((value) => {
              dependencyValid = dependencyValid || value === fieldDepending.value;
            });
            return dependencyValid;
          }
          if (fieldDepending.value === field.participating_field.dependence_value) {
            return true;
          }
          return false;
        }
        return true;
      }
      return true;
    },

    getEventBrandForm() {
      getEventBrandFields(this.contextEvent)
        .then((response) => {
          if (response.data.participating_fields) {
            this.fields = response.data.participating_fields
              .sort((prev, next) => {
                if (prev.order < next.order) {
                  return -1;
                }
                return 1;
              });
            if (this.fields && this.fields.length > 0) {
              // Fields are sorted by custom order and the name field is removed if exists

              let optionsValues: Array<string>;

              this.fields.forEach((field, i) => {
                const participatingField = field.participating_field;
                if (participatingField) {
                  const fieldType = participatingField.field_type;
                  const fieldValidation = participatingField.validation;

                  let title;
                  if (participatingField.show_title && participatingField.title) {
                    title = firstLetterUpperCase(convertBridgeTitles(participatingField.title));
                  }
                  if (
                    (fieldType === 'select' || fieldType === 'radio' || fieldType === 'checkbox') &&
                    participatingField.options
                  ) {
                    optionsValues = participatingField.options
                      .split(',')
                      .map((string) => string.trim());

                    const options: IOption[] = optionsValues.map((option) => ({
                      value: option,
                      label: firstLetterUpperCase(option),
                      checked: false,
                    }));

                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      // We need the custom field to have a default value for the select
                      message: participatingField.mandatory
                        ? this.translations.common.mandatory_field
                        : undefined,
                      value: '',
                      options,
                      disabled: !participatingField.editable,

                    });
                  } else if (
                    fieldType === 'description' ||
                    fieldType === 'url' ||
                    fieldType === 'label'
                  ) {
                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      // For some reason, description's field value is saved in 'options'
                      value: participatingField.options,
                      disabled: !participatingField.editable,
                    });
                  } else if (fieldType === 'file' && fieldValidation.includes('image')) {
                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      // For some reason, description's field value is saved in 'options'
                      value: participatingField.options,
                      message: participatingField.mandatory
                        ? this.translations.common.mandatory_field
                        : undefined,
                      onFileSelect: (file: File) => {
                        if (this.participatingInputs[i].error) {
                          this.participatingInputs[i] = {
                            ...this.participatingInputs[i],
                            error: false,
                            message: '',
                          };
                        }
                        this.cropFieldId = field.id;
                        this.selectImage(file);
                      },
                    });
                  } else {
                    const index = i;
                    this.participatingInputs.push({
                      id: `exhibitor-${participatingField.id}`,
                      name: `exhibitor_${participatingField.id}`,
                      label: title,
                      type: participatingField.field_type,
                      message: participatingField.mandatory
                        ? this.translations.common.mandatory_field
                        : undefined,
                      onInput: (value: string | number) => this.clearInput(index, value),
                      value: '',
                      disabled: !participatingField.editable,

                    });
                  }
                }
              });
              console.log('---');
              this.populateFields();
            }
          }
        })
        .catch((err) => {
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },

    populateFields() {
      this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
      this.inputs.name.value = this.contextExhibitor.name;
      getEventBrandAnswers(this.exhibitor).then((response) => {
        this.answers = response.data.results;
        this.participatingInputs.forEach(async (input, i) => {
          const answerEntity = this.answers.find((answer) => `exhibitor-${answer.participating_field}` === input.id);
          if (answerEntity && this.fields) {
            const answer = answerEntity.answer as string;
            if (input.type === 'radio') {
              if (answer) {
                let options: IOption[];
                if (answer !== '' && isJson(answer)) {
                  options = JSON.parse(answer) as IOption[];
                } else {
                  options = (<IRadios>input).options.map((option) => ({
                    ...option,
                    checked: option.value === answer,
                  }));
                }
                this.participatingInputs[i] = {
                  ...input,
                  value: answer,
                  options,
                };
                if (this.fields[i]) {
                  this.fields[i].value = answer;
                }

                /*
                  Inputs are redefined in this way rather than using the
                  input variable so that vue is forced to update the template
                */
              }
            } else if (input.type === 'checkbox') {
              let options: IOption[];
              if (answer) {
                if (answer !== '' && isJson(answer)) {
                  options = JSON.parse(answer) as IOption[];
                } else {
                  options = (<IRadios>input).options.map((option) => ({
                    ...option,
                    checked: option.value === answer,
                  }));
                }
                this.participatingInputs[i] = {
                  ...input,
                  value: answer,
                  options,
                };
                if (this.fields[i]) {
                  this.fields[i].value = options;
                }

                /*
                  Inputs are redefined in this way rather than using the
                  input variable so that vue is forced to update the template
                */
              }
            } else if ((<IInput>input).type === 'file') {
              // Initialize fields
              this.participatingInputs[i] = {
                ...input,
              };
              if (this.fields[i]) {
                this.fields[i].value = '';
              }

              try {
                // Get file by id
                if (answer && answer !== '') {
                  const fileResponse = await getFileById(parseInt(answer, 10));
                  this.participatingInputs[i] = {
                    ...input,
                    value: answer,
                    file: fileResponse.data,
                  };
                  if (this.fields[i]) {
                    this.fields[i].value = answer;
                  }
                }
              } catch (error) {
                // file not found
              }
            } else {
              this.participatingInputs[i] = {
                ...input,
                value: answer,
              };

              if (this.fields[i]) {
                this.fields[i].value = answer;
              }
            }

            this.$forceUpdate();
          }
        });
        this.loading = false;
      }).catch((err) => {
        store.commit('addPopup', {
          type: 'danger',
          message: err.message,
          autohide: true,
        });
      });
    },

    onSelectCrop(blob: string) {
      if (this.fields) {
        const file = new File([blob], this.croppedFileName, { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        this.cropSrc = urlCreator.createObjectURL(blob);
        this.fields = this.fields.map((field) => {
          if (field.id === this.cropFieldId) {
            return {
              ...field,
              value: file as any,
              file: undefined,
            };
          }
          return field;
        });
        this.$forceUpdate();
        this.cropping = false;
        this.confirming = true;
      }
    },

    confirmCrop() {
      this.modal.isActive = false;
      if (this.fields) {
        const field = this.fields.find((field) => field.id === this.cropFieldId);
        if (field) {
          const index = this.fields.indexOf(field);
          this.participatingInputs[index] = {
            ...this.participatingInputs[index],
            file: undefined,
          };
          this.$forceUpdate();
        }
      }
    },

    clearInput(index: number, value: string | number) {
      if (this.participatingInputs[index].error) {
        this.participatingInputs[index] = {
          ...this.participatingInputs[index],
          error: false,
          message: '',
          value: value as string,
        };
        this.$forceUpdate();
      }
    },

    cancel() {
      if (this.fields) {
        const { fields } = this;

        const field = fields.find((field) => field.id === this.cropFieldId);
        if (field) {
          const index = this.fields.indexOf(field);
          this.fields[index] = {
            ...this.fields[index],
            value: '',
          };
          this.participatingInputs[index] = {
            ...this.participatingInputs[index],
            value: '',
          };
          this.$forceUpdate();
        }
      }
      this.modal.isActive = false;
    },

    cancelCrop() {
      this.cropping = true;
      this.confirming = false;
      if (this.fields) {
        const field = this.fields.find((field) => field.id === this.cropFieldId);
        if (field) {
          const index = this.fields.indexOf(field);
          this.fields[index].value = '';
          this.participatingInputs[index] = {
            ...this.participatingInputs[index],
            value: '',
          };
        }
      }
    },

    selectImage(file: File) {
      this.croppedFileName = file.name;
      if (this.fields) {
        const field = this.fields.find((field) => field.id === this.cropFieldId);
        if (field && field.participating_field) {
          if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
            if (field.participating_field.validation === 'square_image') {
              this.cropper = {
                ...this.cropper,
                aspectRatio: 1 / 1,
              };
            } else {
              this.cropper = {
                ...this.cropper,
                aspectRatio: null,
              };
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e && e.target && e.target.result) {
                this.cropper = { ...this.cropper, image: e.target.result as string };
                this.cropping = true;
                this.confirming = false;
                this.modal.isActive = true;
              }
            };
            reader.readAsDataURL(file);
          } else {
            const index = this.fields.indexOf(field);
            this.participatingInputs[index] = {
              ...this.participatingInputs[index],
              value: '',
              message: 'Este ficheiro não é uma imagem',
              error: true,
            };
            this.$forceUpdate();
          }
        }
      }
    },

    saveAllFields() {
      this.loading = true;
      const data = { id: this.exhibitor, ...this.basicForm };
      if (data.name && data.name !== '') {
        editExhibitor(data).then(() => {
          this.syncVisitorName(data.name);
          this.loadingBasic = false;
          this.saveParticipating();
        });
      } else {
        this.saveParticipating();
      }
    },
    async saveParticipating() {
      this.loading = true;
      let formInvalid = false;

      if (this.fields) {
        this.fields.forEach((field, i) => {
          if (!field.participating_field) {
            return false;
          }
          if (participatingInputs[i] && this.fieldDependency(field)) {
            if (field.participating_field.mandatory && (!field.value || field.value === '')) {
              this.participatingInputs[i] = {
                ...this.participatingInputs[i],
                error: true,
                message: this.translations.common.emptyFieldNotAllowed,
              };
              this.$forceUpdate();
              formInvalid = true;
            } else if (field.participating_field.validation === 'url') {
              if (!field.value || field.value === '') {
                if (field.participating_field.mandatory) {
                  this.participatingInputs[i] = {
                    ...this.participatingInputs[i],
                    error: true,
                    message: this.translations.common.emptyFieldNotAllowed,
                  };
                  this.$forceUpdate();
                  formInvalid = true;
                }
              } else if (!isUrl(field.value as string)) {
                this.participatingInputs[i] = {
                  ...this.participatingInputs[i],
                  value: field.value as string,
                  error: true,
                  message: 'URL inválido',
                };
                formInvalid = true;
                this.$forceUpdate();
              }
            } else if (field.participating_field.validation === 'email') {
              if (!field.value || field.value === '') {
                if (field.participating_field.mandatory) {
                  this.participatingInputs[i] = {
                    ...this.participatingInputs[i],
                    error: true,
                    message: this.translations.common.emptyFieldNotAllowed,
                  };
                  this.$forceUpdate();
                  formInvalid = true;
                }
              } else if (!isEmail(field.value as string)) {
                this.participatingInputs[i] = {
                  ...this.participatingInputs[i],
                  value: field.value as string,
                  error: true,
                  message: 'URL inválido',
                };
                formInvalid = true;
                this.$forceUpdate();
              }
            }
          }
          return true;
        });

        if (!formInvalid) {
          for (const [i, field] of this.fields.entries()) {
            if (field.participating_field && field.participating_field.field_type === 'file' && field.value !== '') {
              // todo: refactor, this is poop :(, in order to not lose file values
              // Check if the value is id
              if (field.value && (<File>field.value).name) {
                const imageForm = new FormData();
                imageForm.append('file', field.value as string);
                // eslint-disable-next-line no-await-in-loop
                const response = await uploadFile(imageForm);
                if (response.status === 400) {
                  for (const [key, value] of Object.entries(response.data)) {
                    store.commit('addPopup', {
                      message: `${key}: ${value[0]}`,
                      type: 'danger',
                      autohide: false,
                    });
                  }
                }
                this.fields[i] = {
                  ...field,
                  value: response.data.id,
                };
              }
            }
          }
          updateEventBrandAnswers(this.contextEvent.id, this.exhibitor, this.fields)
            .then((response) => {
              this.loading = false;
              console.log(response);
            }).catch((err) => {
              const errors = err.data;
              for (const [key, value] of Object.entries(errors)) {
                this.participatingInputs[key as any] = {
                  ...this.participatingInputs[key as any],
                  message: (<string[]>value)[0],
                  error: true,
                } as any;
              }
              this.loading = false;
            });
        } else {
          this.loading = false;
          window.scrollTo(0, 0);
        }
      }
    }
  }
})

