import {
  IDropdown, IModal, IPopup,
} from '@/components';
import {
  IInput, ICheckboxes, ITextarea, ISelect, IRadios,
} from '@/components/form';
import router from '@/router';
import { IExhibitor } from '@/models/exhibitor';
import store from '@/store';
import { ITranslations } from '../../../translations/index';

export const participatingInputs: Array<IInput | IRadios | ICheckboxes | ITextarea | ISelect> = [];

export const exhibitorSettingsPopup: IPopup = {
  message: '',
  type: '',
};

export interface IExhibitorForm {
  name: IInput;
}

export const inputs: () => IExhibitorForm = () => {
  const translations = store.getters.getTranslations as ITranslations;
  const name = {
    id: 'exhibitor-onboarding-name',
    name: 'exhibitor_onboarding_name',
    type: 'text',
    label: translations.common.name,
    placeholder: translations.exhibitors.onboarding.basicInformation.exhibitor_name_placeholder,
    value: '',
  };
  return { name };
};

export const basicForm = {
  name: '',
};

export const interactionSettingsModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/');
  },
};

export const exhibitorModel: Partial<IExhibitor> = {
  id: 0,
  name: '',
  event: 0,
  sponsor_level: 'ds',
};

export const deviceActionPopup: IPopup = {
  message: '',
  type: '',
};

export const dropdown: IDropdown = {
  isActive: false,
  position: 'right',
};
